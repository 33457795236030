import { useEffect, useState } from 'react';
import { Carousel } from 'flowbite-react';
import requests from '../Requests';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Loader from './Loader';

function BannerCarousel() {
    const [banners, setBanners] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const getDataBanners = async () => {
        const dataBanners = await fetch(requests.requestBanners, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueBanners = await dataBanners.json();
        const result = valueBanners.data.map(data => {
            return {
                "id": data.id,
                "url_content_banner": data.attributes.url_content_banner,
                "image_banner": requests.base_url + data.attributes.image_banner.data.attributes.url
            }
        })
        setBanners(result);
        setIsLoading(false)
    }
    useEffect(() => {
        getDataBanners();
    }, [])
    return (
        <div className={`h-56 sm:h-64 xl:h-80 2xl:h-96 ${isLoading && 'flex items-center justify-center h-screen'}`}>
            {isLoading && <Loader />}
            {!isLoading &&
                <Carousel
                leftControl={<FaChevronLeft className='text-3xl text-gray-800 rounded-full shadow-xl' />}
                rightControl={<FaChevronRight className='text-3xl text-gray-800 rounded-full shadow-xl' />}
                >
                    {banners.map((banner) =>
                        <a key={banner.id} href={banner.url_content_banner} className='flex items-center justify-center'>
                            <img
                            src={banner.image_banner}
                            alt={banner.url}
                            className='object-cover h-96'
                        />
                        </a>
                    )}
                </Carousel>
            }
        </div>
    )
}

export default BannerCarousel