import React, { useEffect, useState } from 'react'
import Marquee from "react-fast-marquee";
import requests from '../Requests';
import { Link } from 'react-router-dom';
import Loader from './Loader';

function RunningText() {
    const [runningTexts, setRunnningTexts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const getRunningText = async () => {
        const dataRunningText = await fetch(requests.requestRunningText, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valRunningTexts = await dataRunningText.json();
        const result = valRunningTexts.data.map((valRunningText) => {
            return {
                "id": valRunningText.id,
                "text": valRunningText.attributes.text,
                "url": valRunningText.attributes.url,
            }
        })
        setRunnningTexts(result);
        setIsLoading(false)
    }

    useEffect(() => {
        getRunningText();
    }, [])

    return (
        <Marquee gradient={false} speed={75} pauseOnHover={true} className='flex justify-around p-1 mx-auto text-white bg-blue-logo-kemdikbud gap-x-6 container-fluid '>
            {isLoading && <Loader />}
            {!isLoading &&
                <>
                    {runningTexts.map((runningText) =>
                        <p className='mx-32 text-lg font-light' key={runningText.id}>
                            {runningText.url && runningText.url.includes('http')
                                ? <a href={runningText.url} target='_blank' rel="noreferrer" className='whitespace-pre-wrap'>{runningText.text}</a>
                                : <Link to={runningText.url} className='whitespace-pre-wrap'>{runningText.text}</Link>
                            }
                        </p>
                    )}
                </>
            }

        </Marquee>
    )
}

export default RunningText