import { useEffect, useState } from 'react'
import requests from '../Requests';
import { FaTimes, FaChevronDown } from 'react-icons/fa'
import { IconContext } from "react-icons";
import { Link } from 'react-router-dom';
import Loader from './Loader';


function Drawer({ children, isOpen, setIsOpen }) {
    const [menus, setMenus] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true)

    const toggleDropdownOpen = (id) => {
        setDropdownOpen({
            "id": id,
            "status": !status
        })
    }

    const getDataMenus = async () => {
        const dataMenus = await fetch(requests.requestMenus, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueMenus = await dataMenus.json();
        setMenus(valueMenus.menu.items);
        setIsLoading(false)
    }

    useEffect(() => {
        getDataMenus();
    }, [])
    return (
        <main
            className={
                " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
                (isOpen
                    ? " transition-opacity opacity-100 duration-500 translate-x-0  "
                    : " transition-all delay-500 opacity-0 translate-x-full  ")
            }
        >
            <section
                className={
                    " w-screen max-w-lg right-0 absolute bg-blue-kemdikbud h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
                    (isOpen ? " translate-x-0 " : " translate-x-full ")
                }
            >
                <article className="relative flex flex-col w-screen h-full max-w-lg pb-10 space-y-6 overflow-y-scroll">
                    <header className="p-4 text-lg font-bold text-white" onClick={() => { setIsOpen(false) }}>
                        <IconContext.Provider value={{ color: "white", size: "24", className: "global-class-name" }}>
                            <div>
                                <FaTimes />
                            </div>
                        </IconContext.Provider>
                    </header>
                    <ul className={`flex flex-col gap-8 p-5 ${isLoading && 'items-center justify-center h-screen'}`}>
                        {isLoading && <Loader />}
                        {!isLoading &&
                            <>
                                <li className='text-xl'>
                                    <a className='flex flex-row items-center text-white' href='/'>
                                        Beranda
                                    </a>
                                </li>
                                {menus.map((menu) =>
                                    <li key={menu.id} className='text-xl' onClick={() => toggleDropdownOpen(menu.id, setStatus(!status))}>
                                        <a className='flex flex-row items-center text-white'
                                            href={menu.children.length > 0 ? '#' : menu.url}
                                        >
                                            {menu.title}&nbsp;
                                            {menu.children.length > 0 &&
                                                <FaChevronDown />
                                            }
                                        </a>
                                        {menu.children.length > 0 &&
                                            <ul className={`${dropdownOpen.id === menu.id && dropdownOpen.status ? 'flex flex-col gap-4 bg-white text-blue-kemdikbud p-5 rounded-md' : 'hidden'}`}>
                                                {menu.children.map((submenu) =>
                                                    <li key={submenu.id}>
                                                        {submenu.url.includes('http')
                                                            ? <a href={submenu.url} target={submenu.target ? submenu.target : '_blank'} rel="noreferrer" className='whitespace-pre-wrap' onClick={() => { setIsOpen(false) }}>{submenu.title}</a>
                                                            : <Link to={submenu.url} className='whitespace-pre-wrap' onClick={() => { setIsOpen(false) }}>{submenu.title}</Link>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </li>
                                )}
                            </>
                        }
                    </ul>
                </article>
            </section>
            <section
                className="w-screen h-full cursor-pointer "
                onClick={() => {
                    setIsOpen(false);
                }}
            ></section>
        </main>
    )
}

export default Drawer