import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import requests from '../Requests';
import TrInformasiPublik from '../components/TrInformasiPublik';
import { Link } from 'react-router-dom'

function InformasiPublik() {
  let { slug } = useParams();
  const [dataInformasiPublik, setDataInformasiPublik] = useState({ "NamaInformasiPublik": null, "id": null });
  const [loading, setLoading] = useState(true);
  const [dataSubInformasiPubik, setDataSubInformasiPublik] = useState([]);


  useEffect(() => {
    const getDataInformasi = async () => {
      const dataInformasis = await fetch(`${requests.reqestInformasiPubliks}${slug}`, {
        method: 'get',
        headers: new Headers({
          "Authorization": `Bearer ${requests.token_web}`
        })
      });
      const valueInformasis = await dataInformasis.json();
      const informasi = valueInformasis.data[0].attributes;
      setDataInformasiPublik(informasi);
      if (informasi.sub_informasi_publiks.data.length > 0) {
        const valSubDataInformasiPublik = informasi.sub_informasi_publiks.data.map((subInformasiPublik) => {
          return {
            "idSubInformasiPublik": subInformasiPublik.id,
            "namaSubInformasiPublik": subInformasiPublik.attributes.namaSubInformasiPublik,
            "slugSubInformasiPublik": subInformasiPublik.attributes.Slug,
            "hasChildSubInformasiPublik": subInformasiPublik.attributes.hasChildren,
            "urlSubInformasiPublik": subInformasiPublik.attributes.url
          }
        })
        setDataSubInformasiPublik(valSubDataInformasiPublik);
      }
      setLoading(false);
      // console.log(dataSubInformasiPubik);
    }
    getDataInformasi();

  }, [slug])

  return (
    <div className={`container p-5 mx-auto ${loading && 'flex items-center justify-center h-screen'}`}>
      {loading && <Loader />}
      {!loading &&
        <>
          <div className='flex items-center justify-center'>
            <article className=''>
              <h1 className='mb-5 text-3xl font-semibold text-center'>{dataInformasiPublik.NamaInformasiPublik}</h1>
              <ul className='w-11/12 p-5 pt-0 border shadow-xl rounded-xl'>
                {dataSubInformasiPubik.map((subInformasiPublik) =>
                  <div key={subInformasiPublik.idSubInformasiPublik}>
                    <li className='font-semibold text-[18px] mt-5'>
                      {!subInformasiPublik.hasChildSubInformasiPublik
                        ? <>
                          {subInformasiPublik.urlSubInformasiPublik !== '' && subInformasiPublik.urlSubInformasiPublik.includes('http')
                          ? <a
                          href={subInformasiPublik.urlSubInformasiPublik}
                          className='hover:text-blue-kemdikbud'
                          target={'_blank'}
                          rel='noreferrer'
                          >
                          {subInformasiPublik.namaSubInformasiPublik}
                        </a>
                        : <Link to={subInformasiPublik.urlSubInformasiPublik} className='hover:text-blue-kemdikbud'>{subInformasiPublik.namaSubInformasiPublik}</Link>
                          }
                        </>
                        : <>{subInformasiPublik.namaSubInformasiPublik}</>
                      }
                    </li>
                    {subInformasiPublik.hasChildSubInformasiPublik && <TrInformasiPublik slug={subInformasiPublik.slugSubInformasiPublik} />}
                  </div>
                )}
              </ul>
            </article>
          </div>
        </>
      }
    </div>
  )
}

export default InformasiPublik