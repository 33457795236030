import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import requests from '../Requests'
import LinkPermohonan from './LinkPermohonan';
import Loader from './Loader';

function PemohonCounter() {

    const [jmlPemohon, setJmlPemohon] = useState('');
    const [isLoading, setIsLoading] = useState(true)

    const getJmlPemohon = async () => {
        const dataJmlPemohon = await fetch(requests.requestDataPemohonHome, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueJmlPemohon = await dataJmlPemohon.json();
        const jumlahPemohon = valueJmlPemohon.data[0].attributes;
        setJmlPemohon(jumlahPemohon);
        setIsLoading(false)
    }



    useEffect(() => {
        getJmlPemohon()
    }, [])

    return (
        <div className='flex flex-col items-center justify-center gap-5 mb-5'>
            {isLoading && <Loader />}
            {!isLoading &&
                <CountUp start={0} end={jmlPemohon.jumlah} delay={0} separator=",">
                    {({ countUpRef }) => (
                        <div className='flex flex-col items-center justify-center gap-5 p-[0.5rem] w-full text-center text-black bg-yellow-logo-kemdikbud'>
                            <a href='https://www.kemdikbud.go.id/main/tentang-kemdikbud/rekap-jumlah-pemohon-informasi-publik' target={'_blank'} rel="noreferrer">
                                <p className='text-lg'>Jumlah Pemohon Informasi Publik Tahun {jmlPemohon.Tahun}</p>
                                <span ref={countUpRef} className="text-5xl hover:underline" />
                            </a>
                        </div>
                    )}
                </CountUp>
            }
            <LinkPermohonan />
        </div>
    )
}

export default PemohonCounter