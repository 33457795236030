import parse, { domToReact } from 'html-react-parser'
import DOMPurify from 'dompurify'
import React from 'react'

// export function replaceNode() {}

export default function html({ html }) {
    const htmlOptions = {
        replace:
            ({ name, attribs, children, next }) => {
                if (name === 'table') {
                    return <table className='border border-collapse table-auto parsed-table not-prose'>{domToReact(children, htmlOptions)}</table>;
                }

                if (name === 'figure') {
                    return <>{domToReact(children, htmlOptions)}</>;
                }
            }
    }
    const content = DOMPurify.sanitize(html)
    return parse(content, htmlOptions)
}