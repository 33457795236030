const API_URL='https://ppid.kemdikbud.go.id/senayan';
const PORT='1337'
// const BASE_URL=`${API_URL}:${PORT}`
const BASE_URL=`${API_URL}`

const requests = {
    base_url: BASE_URL,
    token_web: 'a8535dfe24edec3e7794ea68f1bd0c6a56e589525566894a8ca3b62db54f3b2c73edb7feb9a8e4a799b58133db1b0cdfebfafa1febf24a304483a57a315ef72701b1ebda80120321a46bfa8fd023c52724aa90b6dc3e2e8e9d6cff606b4b7eb8954d79a3b6c2afe3020dc881fc1a327c1a240f52ee804dbde496f4acba2dcfb2',
    requestMenus: `${BASE_URL}/api/menus/ppid-web-menu?nested`,
    requestLogo: `${BASE_URL}/api/logo-web?populate=logo_image`,
    requestBanners: `${BASE_URL}/api/banners?populate=image_banner`,
    requestKategoriInformasis: `${BASE_URL}/api/kategori-informasis?populate=image_kategori&sort[0]=id`,
    requestSosmed: `${BASE_URL}/api/social-medias?populate=icon_sosmed`,
    requestDataInformasis: `${BASE_URL}/api/kategori-informasis?filters[slug][$eq]=`,
    requestHalamanStatis: `${BASE_URL}/api/pages?filters[slug][$eq]=`,
    requestRunningText: `${BASE_URL}/api/running-texts`,
    requestYoutubeEmbed: `${BASE_URL}/api/youtube-embed`,
    requestDataPemohonHome: `${BASE_URL}/api/pemohon-informasis?filters[isHome][$eq]=true`,
    requestDataPemohons: `${BASE_URL}/api/pemohon-informasis`,
    requestLinkPermohonan: `${BASE_URL}/api/link-permohonan-informasi`,
    reqestInformasiPubliks: `${BASE_URL}/api/informasi-publiks?populate=%2A&filters[Slug][$eq]=`,
    reqestInformasiPubliksWithImage: `${BASE_URL}/api/informasi-publiks?populate=imageInformasiPublik`,
    requestListInfPublik: `${BASE_URL}/api/sub-informasi-publiks?populate[0]=list_inf_publiks&filters[Slug][$eq]=`,
    requestHalamanNotFound: `${BASE_URL}/api/page-not-found`
}


export default requests