import React, { useState, useEffect } from 'react'
import requests from '../Requests';
import Loader from './Loader';

function YoutubeEmbed() {
    const [youtubeEmbed, setYoutubeEmbed] = useState({});
    const [isLoading, setIsLoading] = useState(true)

    const getYoutubeEmbed = async () => {
        const dataYoutubeEmbed = await fetch(requests.requestYoutubeEmbed, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valYoutubeEmbed = await dataYoutubeEmbed.json();
        const resYoutubeEmbed = valYoutubeEmbed.data.attributes;
        const result = {
            "url": resYoutubeEmbed.unique_url,
        }
        setYoutubeEmbed(result);
        setIsLoading(false)
    }

    useEffect(() => {
        getYoutubeEmbed();
    }, [])

    return (
        <div className='container flex items-center justify-center p-3 mx-auto mb-10'>
            {isLoading && <Loader />}
            {!isLoading && 
                <div className="aspect-w-16 aspect-h-9">
                <iframe src={`https://www.youtube.com/embed/${youtubeEmbed.url}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className='lg:w-[64rem] lg:h-[36rem]'></iframe>
            </div>
            }
        </div>
    )
}

export default YoutubeEmbed