import React, { useState, useEffect } from 'react'
import requests from '../Requests'
import Loader from './Loader';

function LinkPermohonan() {

    const [linkReqPemohon, setLinkReqPemohon] = useState('');
    const [isLoading, setIsLoading] = useState(true)

    const getLinkPermohonan = async () => {
        const dataLinkPermohonan = await fetch(requests.requestLinkPermohonan, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        })
        const valLinkPermohonan = await dataLinkPermohonan.json()
        const linkPermohonan = valLinkPermohonan.data.attributes
        setLinkReqPemohon(linkPermohonan)
        setIsLoading(false)
    }

    useEffect(() => {
        getLinkPermohonan()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading &&
                <>
                    <a href={linkReqPemohon.url} target={'_blank'} rel="noreferrer" className='p-5 font-semibold text-white border rounded-3xl bg-blue-logo-kemdikbud hover:bg-yellow-logo-kemdikbud hover:text-black'>{linkReqPemohon.judul_tombol}</a>
                </>
            }
        </>
    )
}

export default LinkPermohonan