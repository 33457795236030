import React, { useEffect, useState } from 'react';
// import { Card } from 'react-daisyui';
import { Link } from 'react-router-dom';
// import InformasiPublik from '../pages/InformasiPublik';
import requests from '../Requests';
import Loader from './Loader';

function CardSection() {

    const [kategoriInformasis, setKategoriInformasis] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const getKategoriInformasis = async () => {
        const dataKategoriInformasis = await fetch(requests.reqestInformasiPubliksWithImage, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueKategoriInformasis = await dataKategoriInformasis.json();
        const result = valueKategoriInformasis.data.map((valueKategoriInformasi) => {
            return {
                "id": valueKategoriInformasi.id,
                "judul_kategori": valueKategoriInformasi.attributes.NamaInformasiPublik,
                "slug": valueKategoriInformasi.attributes.Slug,
                "thumb_kategori": requests.base_url + valueKategoriInformasi.attributes.imageInformasiPublik.data.attributes.formats.thumbnail.url,
            }
        })
        setKategoriInformasis(result);
        setIsLoading(false)
    }

    useEffect(() => {
        getKategoriInformasis();
        // console.log(kategoriInformasis);
    }, [])

    return (
        <div className={`container flex flex-row items-center justify-around w-full gap-5 p-5 mx-auto overflow-x-auto overflow-y-auto lg:gap-3 md:flex-row lg:overflow-hidden ${isLoading ? 'justify-center h-screen' : 'justify-around'}`}>
            {isLoading && <Loader />}
            {!isLoading &&
                <>
                    {kategoriInformasis.map((kategoriInformasi) =>
                        <div className='flex items-center justify-center overflow-y-hidden transition duration-500 ease-in-out delay-100 border shadow-lg 1/3 lg:w-1/5 rounded-3xl hover:translate-y-1 hover:scale-110 hover:bg-yellow-logo-kemdikbud' key={kategoriInformasi.id}>
                            <Link to={`/informasi-publik/${kategoriInformasi.slug}`}>
                                <img
                                    src={kategoriInformasi.thumb_kategori}
                                    alt={kategoriInformasi.judul_kategori}
                                    className='object-cover'
                                />
                            </Link>
                        </div>
                    )
                    }
                </>
            }
        </div>
    )
}

export default CardSection