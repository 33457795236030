import React, { useState, useEffect } from 'react'
import RenderHtml from '../components/RenderHtml';
import requests from '../Requests';
import Loader from '../components/Loader';

function NotFound() {

  const [halamanStatis, setHalamanStatis] = useState({
    "body": null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getHalamanStatis = async () => {
      const dataHalamanStatis = await fetch(`${requests.requestHalamanNotFound}`, {
        method: 'get',
        headers: new Headers({
          "Authorization": `Bearer ${requests.token_web}`
        })
      });
      const valueHalamanStatis = await dataHalamanStatis.json();
      const resalamanStatis = valueHalamanStatis.data.attributes;
      console.log(valueHalamanStatis);
      const result = {
        "body": resalamanStatis.body,
      }
      setHalamanStatis(result);
      setLoading(false);
    }
    getHalamanStatis();

  }, [])

  return (
    <div className={`container p-5 mx-auto ${loading && 'flex items-center justify-center h-screen'}`}>
      {loading && <Loader />}
      {!loading &&
        <div className='flex items-center justify-center'>

          <article className='prose max-w-none'>
            <RenderHtml html={halamanStatis.body} />
          </article>
        </div>
      }
    </div>
  )
}

export default NotFound