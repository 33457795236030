import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RenderHtml from '../components/RenderHtml';
import requests from '../Requests';
import Loader from '../components/Loader';

function StaticPage() {
  let { slug } = useParams();
  const [halamanStatis, setHalamanStatis] = useState({
    "judul": null,
    "content": null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getHalamanStatis = async () => {
      const dataHalamanStatis = await fetch(`${requests.requestHalamanStatis}${slug}`, {
        method: 'get',
        headers: new Headers({
          "Authorization": `Bearer ${requests.token_web}`
        })
      });
      const valueHalamanStatis = await dataHalamanStatis.json();
      const resalamanStatis = valueHalamanStatis.data[0].attributes;
      const result = {
        "judul": resalamanStatis.judul,
        "content": resalamanStatis.content, 
      }
      setHalamanStatis(result);
      setLoading(false);
    }
    getHalamanStatis();

  }, [slug])

  return (
    <div className={`container p-5 mx-auto ${loading && 'flex items-center justify-center h-screen'}`}>
      {loading && <Loader />}
      {!loading &&
        <div className='flex items-center justify-center'>
            
        <article className='prose max-w-none'>
          <h1 className='mb-5 text-3xl font-semibold text-center'>{halamanStatis.judul}</h1>
          <RenderHtml html={halamanStatis.content} />
        </article>
      </div>
      }
    </div>
  )
}

export default StaticPage