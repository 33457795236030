import React, { useEffect, useState } from 'react'
import { Footer } from 'react-daisyui';
import requests from '../Requests';
import Loader from './Loader';
import SosmedFooter from './SosmedFooter';
import playstore from '../assets/images/playstore.png';

function FooterWeb() {

    const [isLoading, setIsLoading] = useState(true)
    const [logoWeb, setLogoWeb] = useState([]);

    const getDataLogo = async () => {
        const dataLogo = await fetch(requests.requestLogo, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueLogo = await dataLogo.json();
        const result = {
            "logo_text_ppid": valueLogo.data.attributes.logo_text_ppid,
            "logo_text_kemdikbud": valueLogo.data.attributes.logo_text_kemdikbud,
            "logo_image": requests.base_url + valueLogo.data.attributes.logo_image.data.attributes.url,
            "logo_text_singkat_ppid": valueLogo.data.attributes.logo_text_singkat_ppid,
            "logo_text_singkat_kemdikbud": valueLogo.data.attributes.logo_text_singkat_kemdikbud,
            "alamat": valueLogo.data.attributes.alamat,
        }
        setLogoWeb(result);
        setIsLoading(false)
    }

    useEffect(() => {
        
        getDataLogo();
        
    }, [])

    return (
        <Footer className={`flex flex-col items-center text-white p-7 lg:p-5 bg-primary lg:flex-row ${isLoading ? 'justify-center h-screen' : 'justify-between'}`}>
        {isLoading && <Loader />}
        {!isLoading && 
        <>
        <div className='flex flex-col items-center justify-center h-24 lg:flex-row md:flex-row'>
            <img src={logoWeb.logo_image} alt="logo-kemdikbud" />
            <div className='hidden text-lg lg:block'>
                <p className='font-semibold'>{logoWeb.logo_text_ppid}</p>
                <p>{logoWeb.logo_text_kemdikbud}</p>
                <p className='text-xs'>{logoWeb.alamat}</p>
                
            </div>
            <div className='flex flex-col items-center justify-center text-xs lg:hidden'>
                <p>{logoWeb.logo_text_singkat_ppid}</p>
                <p>{logoWeb.logo_text_singkat_kemdikbud}</p>
                <p className='text-xs text-center'>{logoWeb.alamat}</p>
            </div>
        </div>
        </>
        }
            <div>
                <img src={playstore} className={`object-contain max-h-[125px]`} />
            </div>
            <SosmedFooter logo={logoWeb} />
        </Footer >
    )
}

export default FooterWeb