import React from 'react';
import BannerCarousel from '../components/BannerCarousel';
import CardSection from '../components/CardSection';
import PemohonCounter from '../components/PemohonCounter';
import RunningText from '../components/RunningText';
import YoutubeEmbed from '../components/YoutubeEmbed';
// import { Line } from 'react-chartjs-2';


function Home() {
  return (
    <main className="flex flex-col justify-between gap-3">
      <RunningText />
      <BannerCarousel />
      <CardSection />
      <PemohonCounter />
      <YoutubeEmbed />
    </main>
  )
}

export default Home