import { useEffect, useState } from 'react';
import { Navbar } from 'react-daisyui';
import MenuAtas from './MenuAtas';
import { FaBars } from 'react-icons/fa'
import { IconContext } from "react-icons";
import requests from '../Requests';
import Loader from './Loader';

function Header({ setIsOpen }) {

  const [logoWeb, setLogoWeb] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const getDataLogo = async () => {
    const dataLogo = await fetch(requests.requestLogo, {
      method: 'get',
      headers: new Headers({
        "Authorization": `Bearer ${requests.token_web}`
      })
    });
    const valueLogo = await dataLogo.json();
    const result = {
      "logo_text_ppid": valueLogo.data.attributes.logo_text_ppid,
      "logo_text_kemdikbud": valueLogo.data.attributes.logo_text_kemdikbud,
      "logo_image": requests.base_url + valueLogo.data.attributes.logo_image.data.attributes.url,
      "logo_text_singkat_ppid": valueLogo.data.attributes.logo_text_singkat_ppid,
      "logo_text_singkat_kemdikbud": valueLogo.data.attributes.logo_text_singkat_kemdikbud,
    }
    setLogoWeb(result);
    setIsLoading(false)
  }

  useEffect(() => {
    getDataLogo();
  }, [])

  return (
    <div className={`flex items-center justify-center w-full gap-2 px-5 mx-auto font-sans text-white bg-blue-kemdikbud ${isLoading && 'h-screen'}`}>
      <Navbar>
        {isLoading && <Loader />}
        {!isLoading &&
          <>
            <div className="flex-1">
              <a href='/' className='flex flex-row items-center gap-1 2xl:gap-3'>
                <img src={logoWeb.logo_image} alt='' />
                <div className='flex flex-col'>
                  <p className='hidden text-sm 2xl:text-md xl:block'>{logoWeb.logo_text_ppid}</p>
                  <p className='hidden text-sm 2xl:text-md xl:block'>{logoWeb.logo_text_kemdikbud}</p>
                  <p className='block xl:hidden'>{logoWeb.logo_text_singkat_ppid}</p>
                  <p className='block xl:hidden'>{logoWeb.logo_text_singkat_kemdikbud}</p>
                </div>
              </a>
            </div>
            <div className='flex-none'>
              <div className='block lg:hidden' onClick={() => setIsOpen(true)}>
                <IconContext.Provider value={{ color: "white", size: "24", className: "global-class-name" }}>
                  <div>
                    <FaBars />
                  </div>
                </IconContext.Provider>
              </div>

            </div>
            <div className="flex-none hidden lg:block">
              <MenuAtas />
            </div>
          </>
        }

      </Navbar>
    </div>
  )
}

export default Header