import React, { useEffect, useState } from 'react';
import { Menu } from 'react-daisyui';
import { Link } from 'react-router-dom';
import requests from '../Requests';
import { FaChevronDown, FaHome } from 'react-icons/fa'
import Loader from './Loader';

function MenuAtas() {
  const [menus, setMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const getDataMenus = async () => {
    const dataMenus = await fetch(requests.requestMenus, {
      method: 'get',
      headers: new Headers({
        "Authorization": `Bearer ${requests.token_web}`
      })
    });
    const valueMenus = await dataMenus.json();
    setMenus(valueMenus.menu.items);
    setIsLoading(false)
  }

  useEffect(() => {
    getDataMenus();
  }, [])


  return (
    <Menu horizontal={true} className={`flex flex-row gap-3 ${isLoading && 'items-center justify-center'}`}>
      {isLoading && <Loader />}
      {!isLoading &&
        <>
        <Menu.Item>
          <a className='flex flex-row gap-1 px-1 text-sm 2xl:px-2 2xl:gap-1 2xl:text-lg hover:text-yellow-500' href='/'>
            <FaHome className='hidden lg:block' />
          </a>
        </Menu.Item>
          {menus.map((menu) =>
            <Menu.Item key={menu.id}>
              <a className='flex flex-row gap-1 px-1 text-sm 2xl:px-2 2xl:gap-1 2xl:text-lg hover:text-yellow-500' href={menu.children.length > 0 ? '#' : menu.url}>
                {menu.title}
                {menu.children.length > 0 &&
                  <FaChevronDown className='text-xs' />
                }
              </a>
              {menu.children.length > 0 &&
                <Menu horizontal={false} className='right-0 z-50 text-black bg-white border rounded-lg'>
                  {menu.children.map((submenu) =>
                    <Menu.Item key={submenu.id} className='w-64'>
                      {submenu.url.includes('http')
                        ? <a
                          href={submenu.url}
                          target={submenu.target ? submenu.target : '_blank'}
                          rel="noreferrer"
                          className='text-sm whitespace-pre-wrap 2xl:text-lg hover:text-white hover:bg-blue-logo-kemdikbud'
                        >
                          {submenu.title}
                        </a>
                        : <Link to={submenu.url} className='text-sm whitespace-pre-wrap 2xl:text-lg hover:text-white hover:bg-blue-logo-kemdikbud'>{submenu.title}</Link>
                      }
                    </Menu.Item>
                  )}
                </Menu>
              }
            </Menu.Item>
          )}
        </>
      }
    </Menu>
  )
}

export default MenuAtas