import React, { useEffect, useState } from 'react'
import requests from '../Requests'
import Loader from './Loader';
import { Link } from 'react-router-dom'

function TrInformasiPublik({ slug }) {
    const [listInfPubliks, setListInfPubliks] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    

    useEffect(() => {
        const getDataListInfPublik = async () => {
            const dataListInfPublik = await fetch(`${requests.requestListInfPublik}${slug}`, {
                method: 'get',
                headers: new Headers({
                    "Authorization": `Bearer ${requests.token_web}`
                })
            })
            const valueListInfPublik = await dataListInfPublik.json()
            const resListInfPubliks = valueListInfPublik.data[0].attributes.list_inf_publiks.data.map((listInfPublik) => {
                return {
                    "idListInfPublik": listInfPublik.id,
                    "namaListInfPublik": listInfPublik.attributes.namaListInfPublik,
                    "slugListInfPublik": listInfPublik.attributes.Slug,
                    "urlListInfPublik": listInfPublik.attributes.url
                }
            })
            setListInfPubliks(resListInfPubliks)
            setIsLoading(false)
        }
        getDataListInfPublik()
    }, [slug])

    return (
        <ul className='ml-5'>
            {isLoading && <Loader />}
            {!isLoading &&
                <>
                    {[].concat(listInfPubliks).sort((a, b) => a.idListInfPublik > b.idListInfPublik ? 1 : -1).map((listInfPublik) =>
                        <li key={listInfPublik.idListInfPublik}>
                            {listInfPublik.urlListInfPublik !== '' && listInfPublik.urlListInfPublik.includes('http')
                            ? <a 
                            href={listInfPublik.urlListInfPublik} 
                            className='hover:text-blue-logo-kemdikbud hover:font-semibold'
                            target={'_blank'}
                            rel='noreferrer'
                            >
                                {listInfPublik.namaListInfPublik}
                            </a>
                            : <Link to={listInfPublik.urlListInfPublik} className='hover:text-blue-logo-kemdikbud hover:font-semibold'>{listInfPublik.namaListInfPublik}</Link>
                            }
                            
                        </li>
                    )}
                </>
            }
        </ul>
    )
}

export default TrInformasiPublik