import React, { useEffect, useState } from 'react'
import DynamicIcon from './DynamicIcon.tsx';
import requests from '../Requests';
import Loader from './Loader';

function SosmedFooter({ logo }) {

    const [isLoading, setIsLoading] = useState(true)
    const [sosmeds, setSosmeds] = useState([]);

    const getDataSosmed = async () => {
        const dataSosmed = await fetch(requests.requestSosmed, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${requests.token_web}`
            })
        });
        const valueSosmed = await dataSosmed.json();
        const resultSosmed = valueSosmed.data.map((sosmed) => {
            return {
                "id": sosmed.id,
                "nama": sosmed.attributes.nama_sosmed,
                "url": sosmed.attributes.url_sosmed,
                "icon": sosmed.attributes.icon
            }
        })
        setSosmeds(resultSosmed);
        setIsLoading(false)
    }
    useEffect(() => {
        getDataSosmed();
    }, [])

    return (
        <div className={`flex flex-col items-center gap-6 p-2 mt-3 ${isLoading ? 'justify-center' : 'justify-between'}`}>
            {isLoading && <Loader />}
            {!isLoading &&
                <>
                    <div className='flex flex-row items-center justify-center gap-5'>
                        {sosmeds.map((sosmed) =>
                            <a className='link link-hover' href={sosmed.url} key={sosmed.id} target={'_blank'} rel="noreferrer">
                                <DynamicIcon icon={sosmed.icon} size="2em" color="white" />
                            </a>
                        )}
                    </div>
                    <p className=''>Hak Cipta {logo.logo_text_singkat_kemdikbud} @ {new Date().getFullYear()}</p>
                </>
            }
        </div>
    )
}

export default SosmedFooter