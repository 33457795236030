import React, { useState } from 'react';
// import { Menu, Navbar, Button, Card } from 'react-daisyui'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Drawer from "./components/Drawer";
import Home from './pages/Home';
import InformasiPublik from './pages/InformasiPublik';
import StaticPage from './pages/StaticPage';
import FooterWeb from './components/FooterWeb';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';


function App() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Router forceRefresh={true}>
      <div className="App">
        <div className='flex flex-col h-screen'>
          <Header setIsOpen={setIsOpen} />
          <div className='mb-auto'>
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/informasi-publik/:slug' exact element={<InformasiPublik />} key={document.location.href} />
              <Route path='/page/:slug' exact element={<StaticPage />} key={document.location.href} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
          <Drawer isOpen={isOpen} setIsOpen={setIsOpen}></Drawer>
          <FooterWeb />
        </div>
      </div>
    </Router>
  );
}

export default App;
